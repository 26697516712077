<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Profile</div>
    </header>
    <div class="row row-32">
      <div class="col-lg-3">
        <ul class="das-menu-ed">
          <router-link tag="li" active-class="is-active" to="/profile/profile-edit/main">
            <span class="icon">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 5.9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2zm0 9c3 0 6.1 1.5 6.1 2.1v1.1H5.9V17c0-.6 3.1-2.1 6.1-2.1zM12 4a4 4 0 100 8 4 4 0 000-8zm0 9c-2.7 0-8 1.3-8 4v3h16v-3c0-2.7-5.3-4-8-4z" class="fill-grey"/></svg>
            </span>
            Main information
          </router-link>
          <router-link tag="li" active-class="is-active" to="/profile/profile-edit/person-info">
            <span class="icon">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 15h7v2H7v-2zm0-4h10v2H7v-2zm0-4h10v2H7V7zm12-4h-4.2A3 3 0 0012 1a3 3 0 00-2.8 2H4.6A2 2 0 003 5v14a2 2 0 00.6 1.4c.3.3.6.5 1 .6H19a2 2 0 002-2V5a2 2 0 00-2-2zm-7-.3c.4 0 .8.4.8.8s-.4.8-.8.8a.8.8 0 01-.8-.8c0-.4.4-.8.8-.8zM19 19H5V5h14v14z" class="fill-grey"/></svg>
            </span>
            Personal info
          </router-link>
          <router-link tag="li" active-class="is-active" to="/profile/profile-edit/project">
            <span class="icon">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 6V4h-4v2h4zM4 8v11h16V8H4zm16-2a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V8c0-1.1.9-2 2-2h4V4c0-1.1.9-2 2-2h4a2 2 0 012 2v2h4z" class="fill-grey"/></svg>
            </span>
            Projects
          </router-link>
        </ul>
      </div>
      <div class="col-lg-6">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/_variables.scss";
.das-menu-ed{
  li{
    padding: 10px 12px 10px 48px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: $color3;
    transition: $transition;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: rgba(1,1,1,0);
    cursor: pointer;
    .icon{
      width: 24px;
      height: 24px;
      line-height: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      svg{
        max-width: 100%;
      }
      img{
        max-width: 100%;
      }
      .fill-grey{
        fill: #767676;
        transition: $transition;
      }
    }
    &.is-active{
      background-color: $color4;
      color: $color1;
      .icon{
        .fill-grey{
          fill: $color1;
        }
      }
    }
  }
}
/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .das-menu-ed li:hover{
    background-color: $color4;
  }
}

</style>
